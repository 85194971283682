<template>
  <div class="page-view">
    <!--    <van-nav-bar title="个人请假" safe-area-inset-top />-->
    <div class="header-view">
      <div class="filter-view">
        <van-dropdown-menu style="width:200px;height:100%;padding-left: 20px" active-color="#1778FF">
          <van-dropdown-item v-model="filter.yearmonth" :title="filter.yearmonth?'':'年份'" :options="yearColumns" @change="onOptionChange" />
          <van-dropdown-item v-model="filter.attendanceid" :title="filter.attendanceid?'':'请假类型'" :options="option2" @change="onOptionChange" />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="content-view">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length||refreshing"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          class="hidden-list-view"
          @load="onLoad"
        >
          <div v-for="(item,index) in list" :key="index" class="hidden-item-view" :class="item.extFiled3==='已作废'?'cancel-bg':''">
            <div class="item-top-view" @click.stop="toDetail(item)">
              <div class="flex-row" style="align-items: center">
                <span class="flex-row van-multi-ellipsis--l2" style="font-size: 17px;color: #03081A;font-weight: 550">{{ item.attendanceName||'-' }}</span>
                <div class="tag-view">
                  <img v-if="item.extFiled2==='PC'" src="@/assets/personal/icon_pc.png" alt="app" style="width: 16px;height: 16px">
                  <img v-else src="@/assets/personal/icon_app.png" alt="app" style="width: 16px;height: 16px">
                  <span style="margin-left: 5px">{{ item.extFiled2||'-' }}</span>
                </div>
                <div class="flex-1 " />
                <span class="cell-title-view" style="width: auto">请假天数：</span>
                <span class="cell-value-view" style="font-weight: 550;color: #03081A">{{ item.leaveDay || '-' }}天</span>
              </div>
              <div class="flex-row" style="margin-top:10px;justify-content: space-between;align-items: center">
                <span style="font-size: 15px;color: #03081A">{{ item.leaveStartTime || '-' }}</span>
                <img src="@/assets/personal/icon_arrow.png" alt="arrow" style="width: 64px;height: 6px">
                <span style="font-size: 15px;color: #03081A">{{ item.leaveEndTime || '-' }}</span>
              </div>
              <div class="flex-row" style="margin-top:10px">
                <span class="cell-title-view">申请时间：</span>
                <span class="cell-value-view">{{ item.leaveDate || '-' }}</span>
              </div>
              <div class="flex-row" style="margin-top:10px">
                <span class="cell-title-view">当前节点：</span>
                <span class="cell-value-view" :style="{color: item.extFiled3==='已作废'?'red':''}">{{ item.extFiled3 || '-' }}</span>
              </div>
            </div>
            <div class="item-bottom-view">
              <van-button v-if="(item.extFiled3==='暂存'||item.extFiled3==='提交请假')&&item.extFiled3!=='已完成（历史数据）'" type="default" size="small" style="width: 78px;height: 32px;background: #F5F6F9;color: #03081A;border: none" @click.stop="handleDelete(item)">删除</van-button>
              <van-button v-if="(item.extFiled3==='暂存'||item.extFiled3==='提交请假')&&item.extFiled3!=='已完成（历史数据）'" type="primary" size="small" color="#4B74FF" style="width: 78px;height: 32px" @click.stop="toDetail(item)">处理</van-button>
              <van-button
                v-if="item.extFiled3 !== '暂存'
                  && item.extFiled3 !== '提交请假' && item.extFiled3 !== '已完成（历史数据）'
                  && item.extFiled3 !== '已作废'"
                type="primary"
                size="small"
                color="#4B74FF"
                style="width: 78px;height: 32px"
                @click.stop="handleNullify(item)"
              >作废</van-button>
              <van-button
                v-if="item.extFiled3 !== '暂存'
                  && item.extFiled3 !== '提交请假' && item.extFiled3 !== '已完成（历史数据）'
                  && item.extFiled3 !== '已作废'&& item.extFiled3 !== '审请已通过'"
                type="primary"
                size="small"
                color="#4B74FF"
                style="width: 78px;height: 32px"
                @click.stop="handleRecall(item)"
              >撤回</van-button>
              <van-button
                v-if="item.extFiled3!=='暂存'&&item.extFiled3!=='提交请假'&& item.extFiled3 !== '已完成（历史数据）'
                  && item.extFiled3 !== '已作废'"
                type="primary"
                size="small"
                color="#4B74FF"
                style="width: 78px;height: 32px"
                @click.stop="toUploadFile(item)"
              >附件补录</van-button>
            </div>
          </div>
        </van-list>
        <van-empty v-else description="暂无数据" />
      </van-pull-refresh>

      <div class="add-view">
        <div class="add-txt" @click="addForm">我要<br>请假</div>
      </div>
    </div>
    <van-popup v-model="showRecallPopup" position="center" round :style="{ width:'90vw'}" @close="onPopupClose">
      <div class="popup-view">
        <div class="cell-view">
          <span class="label-txt">撤回原因</span>
          <van-field
            v-model="recallMessage"
            rows="4"
            type="textarea"
            maxlength="100"
            placeholder="请输入撤回原因"
            style="background: #f5f6f9;flex: 1"
          />
        </div>
        <div class="cell-view" style="margin-top: 20px">
          <div class="add-btn" @click="showRecallPopup=false">取消</div>
          <div class="add-btn-primary" style="margin-left: 15px" @click="onSubmitRecall()">提交</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showCancelPopup" position="center" round :style="{ width:'90vw'}" @close="onPopupClose">
      <div class="popup-view">
        <div class="cell-view">
          <span class="label-txt">作废原因</span>
          <van-field
            v-model="cancelMessage"
            rows="4"
            type="textarea"
            maxlength="100"
            placeholder="请输入作废原因"
            style="background: #f5f6f9;flex: 1"
          />
        </div>
        <div class="cell-view" style="margin-top: 20px">
          <div class="add-btn" @click="showCancelPopup=false">取消</div>
          <div class="add-btn-primary" style="margin-left: 15px" @click="onSubmitCancel()">提交</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { postRequest } from '@/utils/api'
import { removeToken } from '@/utils/auth'

export default {
  name: 'Personal',
  data() {
    return {
      list: [],
      showPopup: false,
      filter: {
        yearmonth: this.$dayjs().year().toString(),
        attendanceid: '',
        state: '1'
      },
      option1: [],
      option2: [],
      refreshing: false,
      loading: false,
      finished: false,
      pageNo: 1,
      pageCount: 1,
      init: true,
      showRecallPopup: false,
      showCancelPopup: false,
      recallMessage: '',
      cancelMessage: '',
      clickItem: {}
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userId
    },
    userName() {
      return this.$store.getters.userName
    },
    userInfo() {
      return this.$store.getters.userInfo
    },
    yearColumns() {
      const currentMonth = this.$dayjs().month() + 1
      let currentYear = this.$dayjs().year()
      if (currentMonth === 12) {
        currentYear = this.$dayjs().year() + 1
      }
      let showindex = 5
      if (currentYear - 2024 > 5) {
        showindex = currentYear - 2024
      }
      console.log('yearList===', currentYear)
      const yearList = []
      for (let index = 0; index < showindex; index++) {
        yearList.push({
          text: currentYear - index + '年',
          value: (currentYear - index).toString()
        })
      }
      console.log('yearList===', yearList)
      return yearList
    }
  },
  mounted() {
    removeToken('Authorization')
    removeToken('IscUserId')
    this.initData()
  },
  activated() {
    if (this.init) {
      return
    }
    this.onRefresh()
  },
  methods: {
    initData() {
      this.$store.dispatch('user/setUserInfo', {
        appKey: 'ddmpt_zxjc',
        ticket: this.$route.query.ticket
      }).then(() => {
        this.init = false
        this.getAttendanceType()
        this.onLoad()
      })
    },
    onLoad() {
      if (this.refreshing) {
        this.refreshing = false
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/getUsetLeaveData', {
        ...this.filter,
        pageNum: this.pageNo,
        pageSize: 10
      }).then(res => {
        console.log('res', res)
        this.$toast.clear()
        this.loading = false
        if (!res.data.successful) {
          this.list = []
          return
        }
        this.pageCount = res.data.resultValue.pages
        const dataList = res.data.resultValue.list
        if (res.data.resultValue.pageNum === 1) {
          this.list = dataList
        } else {
          this.list = [...this.list, ...dataList]
        }
        this.pageNo++
        this.finished = this.pageNo > this.pageCount
      }).catch(() => {
        this.$toast.clear()
      })
    },
    onRefresh() {
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.pageNo = 1
      this.onLoad()
    },
    // 获取请假类型
    getAttendanceType() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceData/findAttendanceType', {}).then(res => {
        this.$toast.clear()
        if (!res.data.successful) {
          return
        }
        const list = res.data.resultValue || []
        const options = [{ text: '全部', value: '' }]
        this.option2 = [...options, ...list.filter(e => {
          return e.id !== '1' && e.id !== '2' && e.id !== '3' && e.id !== '9' && e.id !== '10'
        }).map(item => {
          return {
            text: item.attendancename,
            value: item.id
          }
        })]
      })
    },
    onOptionChange(val) {
      this.onRefresh()
    },
    onBack() {
      this.$router.back()
    },
    // 我要请假
    addForm() {
      this.$router.push({
        name: 'personal/addFrom',
        query: {
        }
      })
    },
    toDetail(item) {
      this.$router.push({
        name: 'personal/addFrom',
        query: {
          id: item.id
        }
      })
    },
    toUploadFile(item) {
      this.$router.push({
        name: 'personal/addFrom',
        query: {
          id: item.id,
          upload: true
        }
      })
    },
    handleDelete(item) {
      this.$dialog.confirm({
        title: '提示',
        message: '确定删除，数据删除后不可恢复',
        confirmButtonColor: '#1778FF'
      })
        .then(() => {
          this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 30000
          })
          postRequest('/pems/attendance/tAttendanceLeaveDate/updatePersonalLeaveData', {
            id: item.id,
            delFlag: 0
          }).then(res => {
            this.$toast.clear()
            if (!res.data.successful) {
              this.$toast.fail('删除失败')
              return
            }
            this.$toast.success('删除成功')
            this.onRefresh()
          }).catch(() => {
            this.$toast.fail('删除失败')
          })
        })
    },
    // 作废
    handleNullify(item) {
      if ((item.startPoint === 'AM' &&
          new Date(item.startTime + ' 08:00:00').getTime() - new Date().getTime() <= 0) ||
        (item.startPoint === 'PM' &&
          new Date(item.startTime + ' 13:00:00').getTime() - new Date().getTime() <= 0)) {
        this.$toast('假期进行中，请联系管理员进行作废操作')
        return
      }
      this.showCancelPopup = true
      this.clickItem = item
    },
    // 撤回
    handleRecall(item) {
      this.showRecallPopup = true
      this.clickItem = item
    },
    onPopupClose() {
      this.recallMessage = ''
      this.cancelMessage = ''
    },
    onSubmitRecall() {
      if (!this.recallMessage) {
        this.$toast('请输入撤回原因')
        return
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/recall', {
        id: this.clickItem.id,
        message: this.recallMessage
      }).then(res => {
        if (!res.data.successful) {
          this.$toast.clear()
          this.$toast('撤回失败')
          return
        }
        this.showRecallPopup = false
        this.$toast.clear()
        this.$toast('撤回成功')
        this.onRefresh()
      }).catch(() => {
        this.$toast('撤回失败')
      })
    },
    onSubmitCancel() {
      if (!this.cancelMessage) {
        this.$toast('请输入作废原因')
        return
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/nullifyLeaveDay', {
        id: this.clickItem.id,
        message: this.cancelMessage
      }).then(res => {
        this.$toast.clear()
        if (!res.data.successful) {
          this.$toast('作废失败')
          return
        }
        this.showCancelPopup = false
        this.$toast('作废成功')
        this.onRefresh()
      }).catch(() => {
        this.$toast('作废失败')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page-view {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  background-color: #F5F6F9;

  .header-view{
    width: 100%;
    height: 196px;
    background: linear-gradient(to bottom, #3E70FE 0, #F5F6F9 100%);
  }

  .filter-view{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
  }

  .content-view {
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
    overflow-y: auto;
    margin-top: -160px;

    .top-content-view {
      width: 100%;
      padding: 16px 12px 12px 12px;
      box-sizing: border-box;
      font-size: 17px;
      background-color: white;
      color: #03081A;
    }

    .tips-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #FDF6EB;
      color: #9A6938;
      font-size: 12px;
      padding: 12px;
      box-sizing: border-box;
      line-height: 16px;
    }

    .btn-view {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #03081A;
      font-size: 15px;
      border-radius: 8px;
      border: 1px solid #CFD2D9;
      background-color: white;
    }

    .btn-select-red {
      background-color: #F53F3F;
      color: white;
      border: 0;
    }

    .btn-select-green {
      background-color: #007A77;
      color: white;
      border: 0;
    }

    .hidden-list-view {
      display: flex;
      flex-direction: column;
      padding: 12px;

      .hidden-item-view {
        display: flex;
        flex-direction: column;
        background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0, rgba(255,255,255,1) 100%);
        border-radius: 6px;
        margin-bottom: 8px;
        box-sizing: border-box;
        padding: 16px 12px 10px 12px;

        .item-top-view {
          display: flex;
          flex-direction: column;

          .cell-title-view{
            width: 80px;
            color:#838DA0;
            font-size: 13px;
          }

          .cell-value-view{
            color:#838DA0;
            font-size: 13px;
          }
          .delete-view{
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #fa3f3f;
            color: white;
            font-weight: bold;
            margin-top: 2px;
            font-size: 20px;
          }

          .tag-view{
            margin-left: 10px;
            height: 20px;
            border-radius: 10px;
            background: #ECF1FE;
            font-size: 13px;
            color: #4B74FF;
            padding: 0 10px;
            display: flex;
            align-items: center;
          }
        }

        .item-bottom-view {
          display: flex;
          align-items: center;
          padding-left: 12px;
          font-size: 12px;
          color: #838DA0;
          border-radius: 0 0 8px 8px;
          margin-top: 12px;
          justify-content: flex-end;
          padding-right: 12px;
          column-gap: 10px;
          padding-top: 10px;
          border-top: 1px solid #EFEFEF;
          .danger-level-view{
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            padding: 0 8px;
            font-size: 13px;
            border-radius: 12px 2px 2px 2px;
            background-color: #1778FF;
          }

          .level-JD{
            background-color: #F0A716;
          }

          .level-YB{
            background-color: #007A77;
          }

          .level-ZD{
            background-color: #f53f3f;
          }
        }

        .danger-YB{
          background: rgba(0,122,119,8%);
        }
        .danger-ZD{
          background: rgba(247,186,30,8%);
        }
        .danger-JD{
          background: rgba(255,125,0,8%);
        }
      }

      .cancel-bg{
        background: rgba(245,63,63,0.1);
      }

      .YB{
        background: linear-gradient(to bottom, #F4FFFF 0, #FFFFFF 100%);
        border-top: 1px solid #D6F8F7;
        border-bottom: 1px solid white;
        border-left: 1px solid #D9F8F7;
        border-right: 1px solid #D9F8F7;
      }
      .JD{
        background: linear-gradient(to bottom, #FFFDF6 0, #FFFFFF 100%);
        border-top: 1px solid #FFF4D7;
        border-bottom: 1px solid white;
        border-left: 1px solid #FFFAED;
        border-right: 1px solid #FFFAED;
      }
      .ZD{
        background: linear-gradient(to bottom, #FFFBF8 0, #FFFFFF 100%);
        border-top: 1px solid #FFE0D7;
        border-bottom: 1px solid white;
        border-left: 1px solid #FFF1ED;
        border-right: 1px solid #FFF1ED;
      }

    }

    .add-view{
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 30px;
      bottom: 40px;
      flex-direction: column;
      .add-txt{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        background: #4B74FF;
        color: white;
        border-radius: 50%;
      }
    }
  }
}

.form-view {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 0 10px;
  margin: 0 12px;
  margin-top: 20px;

  .form-cell-view {
    display: flex;
    height: 46px;
    align-items: center;
    font-size: 15px;
    color: #838DA0;
    border-top: 1px solid #EFEFEF;
  }
}

.add-btn-view {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
  color: white;
  border-radius: 25px;
  margin-top: 20px;
  background-color: #007A77;
}

.popup-view{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px;
  box-sizing: border-box;

  .cell-view{
    display: flex;

    .label-txt{
      color: #646566;
      font-size: 14px;
      margin-right: 10px;
    }

    .add-btn {
      flex: 1;
      border: 1px solid #CFD2D9;
      border-radius: 8px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    .add-btn-primary {
      display: flex;
      flex: 1;
      background: #1778FF;
      border-radius: 8px;
      padding: 10px 0;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: white;
    }
  }
}

::v-deep .van-popup--center.van-popup--round {
  border-radius: 8px;
}

::v-deep .van-icon{
  line-height: 19px;
}

::v-deep .van-pull-refresh{
  overflow: auto;
}

::v-deep .van-dropdown-menu__bar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow:none;
}

::v-deep .van-dropdown-menu__item{
  justify-content: flex-start;
}

::v-deep .van-dropdown-menu__title{
  padding: 0 10px 0 0;
  color: white;
  font-size: 13px;
}

</style>
